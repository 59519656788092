<template>
  <div class="container">
    <div class="scroll_area">
      <ul>
        <li v-for="(item, index) in items" :key="index">
          <div class="tag-box" :class="{'active': index === activeIdx}" @click='ev => pickItem(index)'>
            <img :src="getIconLinkItem(index)" :alt="getLabelItem(index)">
            <h4>{{ getLabelItem(index) }}</h4>
          </div>
        </li>
      </ul>
    </div>
    <div class="tab-area" v-if="availableSubcategory">
      <ul class="tab-link">
        <li v-for="(subcate, index) in listSubcategory" :key="index"><button :class="['btn-link', idxActiveSubcategory===index ? 'active' : '']" :disabled="!availPickedCategory" @click="pickSubcategory(subcate.value)">{{ subcate.text }}</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppDealMenuPicker',
  props: {
    pickCodeCategory: {
      type: String,
      default: '',
    },
    pickCodeSubcategory: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeIdx: -1,
      idxActiveSubcategory: -1,
    };
  },
  computed: {
    ...mapGetters({
      menuItems: 'GET_MENU_ITEMS_APP_DEAL',
      availableSubcategory: 'GET_AVAILABLE_SUBCATEGORY',
      getSubcategory: 'GET_ACTIVE_SUBCATEGORIES',
    }),
    items() {
      return this.menuItems;
    },
    availPickedCategory() {
      return this.activeIdx > -1;
    },
    listSubcategory() {
      return this.getSubcategory(this.items?.[this.activeIdx]?.code ?? '');
    },
  },
  watch: {
    pickCodeCategory() {
      this.activeIdx = this.items.findIndex((cate) => cate.code === this.pickCodeCategory);
    },
    pickCodeSubcategory() {
      this.idxActiveSubcategory = this.listSubcategory.findIndex((cate) => cate.value === this.pickCodeSubcategory);
    },
  },
  created() {
    this.pickItem(0);
  },
  methods: {
    pickItem(pIdx) {
      this.idxActiveSubcategory = -1;
      this.activeIdx = pIdx;
      this.$emit('emitMessage', {
        kind: 'pickFilterCategory',
        data: {
          code: this.items?.[pIdx]?.code ?? '',
        },
      });
    },
    getIconLinkItem(pIdx) {
      if (pIdx < 0) return '';
      const item = this.items[pIdx];
      return pIdx === this.activeIdx ? item.icon2 : item.icon;
    },
    getLabelItem(pIdx) {
      if (pIdx < 0) return '';
      return this.items[pIdx].title;
    },
    pickSubcategory(pSubcategory) {
      this.idxActiveSubcategory = pSubcategory === 'The hottest' ? 0 : 1;
      this.$emit('emitMessage', {
        kind: 'pickSubcategory',
        data: {
          codeCategory: this.items?.[this.activeIdx]?.code ?? '',
          codeSubcategory: pSubcategory,
        },
      });
    },
  },
};
</script>

<style scoped>
  .deal-filter .tab-area .tab-link button.btn-link{
    text-decoration: unset;
  }
</style>
